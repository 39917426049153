"use client";

import { type PropsWithChildren } from "react";

import useSelectedLocation from "~/hooks/useSelectedLocation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import SelectLocationModalWidget from "~/widgets/SelectLocationModalWidget";

function SelectLocationWrapper({ children }: PropsWithChildren) {
  const storeInfo = useStoreInfo();
  const { isLocationSelected } = useSelectedLocation();
  const { hasMulticd } = storeInfo;

  if (hasMulticd && !isLocationSelected) {
    return <SelectLocationModalWidget />;
  }

  return children;
}

export default SelectLocationWrapper;
